<template>
  <BaseModal ref="modal">
    <div class="thank">
      <p class="title">Ваша заявка успешно отправлена </p>
      <p class="subtitle">
        Спасибо!<br>
        Ваше сообщение успешно отправлено.<br>
        В ближайшее время мы с Вами свяжемся!
      </p>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/Base/BaseModal'

export default {
  name: 'ModalThank',
  components: {
    BaseModal
  },
  methods: {
    open () {
      this.$refs.modal.open()
    },
    close () {
      this.$refs.modal.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.thank {
  width: 900px;
  text-align: center;

  @media screen and (max-width: $media-sm) {
    width: initial;
  }

  .title {
    font-weight: 600;
    font-size: 28px;
  }
  .subtitle {
    font-size: 28px;
    line-height: 1.6;
    margin-top: 40px;
  }
}
</style>
