<template>
  <span class="error">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'BaseError'
}
</script>

<style lang="scss" scoped>
.error {
  color: red;
  font-size: 12px;
  display: block;
  padding: 1px 0;
}
</style>
