<template>
  <div
    class="input-wrapper"
    :class="{ 'block': block }"
  >
    <input
      class="input"
      :disabled="disabled"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      :class="inputClasses"
      @input="$emit('update:modelValue', $event.target.value)"
      @change="$emit('change', $event.target.value)"
      @blur="$emit('blur', $event.target.value)"
    >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      default: ''
    },
    size: {
      type: String,
      default: 'md',
      validator: val => ['sm', 'md', 'lg'].includes(val)
    },
    placeholder: {
      type: [Number, String],
      default: ''
    }
  },
  emits: {
    'update:modelValue': null,
    change: null,
    blur: null
  },
  computed: {
    inputClasses () {
      return [
        this.size
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
$input-size-sm:                            40px !default;
$input-size-md:                            50px !default;
$input-size-lg:                            60px !default;

.input-wrapper {
  &.block {
    display: block;
    width: 100%;
  }

  .input {
    display: block;
    width: 100%;
    text-indent: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    border-radius: 10px;
    background: #F3F5F7;
    border: none;
    font-family: inherit;

    &:focus {
      outline: 0;
    }

    &.sm {
      height: $input-size-sm;
    }
    &.md {
      height: $input-size-md;
    }
    &.lg {
      height: $input-size-lg;
    }
  }
}
</style>
