<template>
  <div class="captcha">
    Сайт защищен reCAPTCHA в соответствии условиям
    <a :href="privacyPolicy" target="_blank">Политики конфиденциальности</a> и
    <a :href="usageAgreements" target="_blank">Соглашения об использовании</a>.
  </div>
</template>

<script>
export default {
  name: 'AppCaptcha',
  props: {
    privacyPolicy: {
      type: String,
      default: '/privacyPolicy.pdf'
    },
    usageAgreements: {
      type: String,
      default: '/privacyPolicy.pdf'
    }
  }
}
</script>

<style lang="scss" scoped>
$secondary-color:                         #046DA7 !default;

.captcha {
  font-weight: 400;
  font-size: 12px;
  color: #000;

  a {
    color: $secondary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
