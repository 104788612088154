<template>
  <teleport to="body">
    <div
      class="overlay"
      :class="{ overlay_active: isOpen }"
      @mousedown.self="close"
    >
      <div
        v-bind="$attrs"
        class="modal"
        :class="{ modal_active: isOpen }"
      >
        <div
          class="modal__close"
          @click="close"
        >
          &#215;
        </div>
        <slot />
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'BaseModal',
  inheritAttrs: false,
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    open () {
      this.isOpen = true
      document.body.style.overflow = 'hidden'
      document.body.style.paddingRight = '15px'
    },
    close () {
      this.isOpen = false
      document.body.style.overflow = ''
      document.body.style.paddingRight = ''
    }
  }
}
</script>

<style lang="scss" scoped>
$media-sm:                                768px !default;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: .2s;

  &_active {
    z-index: 9999;
    opacity: 1;
  }

  .modal {
    padding: 40px;
    background: #fff;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: 20px;
    min-width: 300px;
    transition: .2s;

    &_active {
      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: $media-sm) {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 0;
      transform: initial;
      padding: 20px;
      overflow: auto;
    }

    &__close {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      cursor: pointer;

      &:hover {
        color: red;
      }
    }
  }
}
</style>
