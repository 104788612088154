<template>
  <label
    class="label"
    :class="{ label_disabled: this.disabled }"
  >
    <input
      type="checkbox"
      class="label__checkbox"
      :checked="checked"
      :value="value"
      :disabled="disabled"
      @change="onChange"
    >

    <div
      class="label__fake"
      :class="[`label__fake_${theme}`]"
    />

    <div class="info">
      <p
        v-text="label"
        class="info__title"
        :class="[`info__title_text-${textSize}`]"
      />

      <slot name="error" />
    </div>
  </label>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    modelValue: {},
    value: {},
    label: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    textSize: {
      type: String,
      default: 'md',
      validator: val => ['sm', 'md'].includes(val)
    },
    theme: {
      type: String,
      default: 'primary',
      validator: val => ['primary', 'gold'].includes(val)
    }
  },
  emits: {
    'update:modelValue': null
  },
  data () {
    return {
      localChecked: false
    }
  },
  computed: {
    isArray () {
      return Array.isArray(this.modelValue)
    },
    checked () {
      if (this.isArray) {
        return this.localChecked.includes(this.value)
      } else {
        return this.localChecked
      }
    }
  },
  watch: {
    modelValue: 'init'
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.isArray) {
        this.localChecked = this.modelValue.slice()
      } else {
        this.localChecked = !!this.modelValue
      }
    },
    onChange (event) {
      if (this.isArray) {
        const exists = this.localChecked.includes(this.value)

        if (exists) {
          this.localChecked = this.localChecked.filter(item => item !== this.value)
        } else {
          this.localChecked.push(this.value)
        }

        this.$emit('update:modelValue', this.localChecked)
      } else {
        this.$emit('update:modelValue', event.target.checked)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$disabled-color:                          #ABABAB !default;
$primary-color:                           #3EBBEA !default;
$dark-color:                              #333333 !default;

.label {
  display: inline-flex;
  align-items: center;
  padding: 2px;
  user-select: none;

  &_disabled {
    color: $disabled-color;
    .label {
      &__fake {
        border-color: $disabled-color;
      }

      &__checkbox:checked + .label__fake {
        background-color: $disabled-color;
      }
    }
  }

  &__checkbox {
    display: none;
  }

  &__checkbox:checked + .label__fake {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 214.155 214.155' style='enable-background:new 0 0 214.155 214.155;' xml:space='preserve'%3E%3Cpath fill='%23fff' d='M74.551,193.448L0,118.896l33.136-33.135l41.415,41.415L181.02,20.707l33.135,33.136L74.551,193.448z'/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;

    &_primary {
      background-color: $primary-color;
    }
    &_gold {
      background-color: $dark-color;
    }
  }

  &__fake {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 4px;
    flex-shrink: 0;

    &_primary {
      border: 2px solid $primary-color;
    }
    &_gold {
      border: 2px solid $dark-color;
    }
  }

  .info {
    margin-left: 14px;

    &__title {
      &_text-sm {
        font-size: 12px;
      }
      &_text-md {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}
</style>
